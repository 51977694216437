import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseView from '../views/BaseView.vue'
import IndexView from '../views/IndexView.vue'
import LoginView from '../views/LoginView.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: BaseView,
    children: [
      {
        path: '/',
        name: 'index',
        meta: { title: '首页' },
        component: IndexView
      },
      {
        path: '/orders',
        component: () => import('../views/orders/Orders.vue'),
        children: [
          {
            path: 'fupin832',
            name: 'ordersfupin',
            meta: { title: '832订单列表' },
            component: () => import('../views/orders/FupinView.vue')
          },
          {
            path: 'fupin_assign',
            name: 'fupinAssign',
            meta: { title: '832待分配订单' },
            component: () => import('../views/orders/FupinAssign.vue')
          },
          {
            path: 'offline',
            name: 'ordersOffline',
            meta: { title: '线下订单列表' },
            component: () => import('../views/orders/OfflineView.vue')
          }
        ]
      },
      {
        path: 'after_sale/fupin',
        meta: { title: '线上订单｜售后订单列表' },
        component: () => import('../views/afterSale/FupinOrders.vue')
      },
      {
        path: 'after_sale/my_fupin',
        meta: { title: '我的售后(832)订单列表' },
        component: () => import('../views/afterSale/MyFupinOrders.vue')
      },
      {
        path: 'after_sale/offline',
        meta: { title: '录入订单｜售后订单列表' },
        component: () => import('../views/afterSale/OfflineOrders.vue')
      },
      {
        path: 'after_sale/my_offline',
        meta: { title: '我的售后(录入)订单列表' },
        component: () => import('../views/afterSale/MyOfflineOrders.vue')
      },
      {
        path: '/order/offline',
        name: 'orderOffline',
        meta: { title: '添加线下订单' },
        component: () => import('../views/order/OfflineView.vue')
      },
      {
        path: '/data/dial_record',
        name: 'DialRecord',
        meta: { title: '通话记录' },
        component: () => import('../views/data/DialRecord.vue')
      },
      {
        path: '/data/sale',
        name: 'SaleData',
        meta: { title: '销售数据' },
        component: () => import('../views/data/SaleData.vue')
      },
      {
        path: '/permission/role',
        name: 'permissionRole',
        meta: { title: '角色权限管理' },
        component: () => import('../views/permission/Role.vue')
      },
      {
        path: '/permission/origin',
        name: 'permissionOrigin',
        meta: { title: '订单来源权限管理' },
        component: () => import('../views/permission/Origin.vue')
      },
      {
        path: '/permission/crm_data_scope',
        name: 'permissionCRMDataScope',
        meta: { title: 'CRM数据可见分组管理' },
        component: () => import('../views/permission/CRMDataScope.vue')
      },
      {
        path: '/permission/menu',
        name: 'permissionMenu',
        meta: { title: '菜单权限管理' },
        component: () => import('../views/permission/PermissionMenuView.vue')
      },
      {
        path: '/permission/interface',
        name: 'permissionInterface',
        meta: { title: '权限接口列表' },
        component: () => import('../views/permission/PermissionInterfaceView.vue')
      },
      {
        path: '/sample/inlist',
        name: 'sampleInlist',
        meta: { title: '样品入库记录列表' },
        component: () => import('../views/sample/InList.vue')
      },
      {
        path: '/sample/outlist',
        name: 'sampleOutlist',
        meta: { title: '样品出库记录列表' },
        component: () => import('../views/sample/OutList.vue')
      },
      {
        path: '/staffs',
        name: 'staffs',
        meta: { title: '员工列表' },
        component: () => import('../views/staff/StaffList.vue')
      },
      {
        path: '/sales/team',
        name: 'SalesTeam',
        meta: { title: '销售团队' },
        component: () => import('../views/staff/SalesTeam.vue')
      },
      {
        path: '/scm/orders',
        name: 'SCMOrders',
        meta: { title: '供应链订单中心' },
        component: () => import('../views/scm/order/orders.vue')
      },
      {
        path: '/scm/goods',
        name: 'SCMGoods',
        meta: { title: '供应链商品库' },
        component: () => import('../views/scm/goods/goodsList.vue')
      },
      {
        path: '/scm/goods/extraCost',
        name: 'SCMGoodsExtraCost',
        meta: { title: '供应链商品附带成本管理' },
        component: () => import('../views/scm/goods/extraCost.vue')
      },
      {
        path: '/scm/goods/create',
        name: 'SCMGoodsCreate',
        meta: { title: '添加供应链商品信息' },
        component: () => import('../views/scm/goods/CreateGoods.vue')
      },
      {
        path: '/scm/goods/category',
        name: 'SCMGoodsCategory',
        meta: { title: '供应链商品分类管理' },
        component: () => import('../views/scm/goods/category.vue')
      },
      {
        path: '/scm/tofactory',
        name: 'SCMToFactory',
        meta: { title: '供应链下单中心' },
        component: () => import('../views/scm/order/toFactory.vue')
      },
      {
        path: '/stores',
        name: 'stores',
        meta: { title: '832店铺列表' },
        component: () => import('../views/store/FupinStores.vue')
      },
      {
        path: '/goods',
        name: 'Goods',
        meta: { title: '商品库' },
        component: () => import('../views/scm/goods/goodsLibrary.vue')
      },
      {
        path: '/my',
        component: () => import('../views/my/MyView.vue'),
        children: [
          {
            path: 'orders/fupin',
            name: 'MyFupinOrders',
            meta: { title: '我的订单-平台订单' },
            component: () => import('../views/my/orders/MyFupinOrders.vue')
          },
          {
            path: 'orders/offline',
            name: 'MyOfflineOrders',
            meta: { title: '我的订单-录入订单' },
            component: () => import('../views/my/orders/MyOfflineOrders.vue')
          },
          {
            path: 'orders/sc',
            name: 'MySCOrders',
            meta: { title: '我的订单-供应链订单' },
            component: () => import('../views/my/orders/MySCOrders.vue')
          },
          {
            path: 'orders/audit',
            name: 'ToAudit',
            meta: { title: '我的订单-待审核订单' },
            component: () => import('../views/my/orders/ToAuditOrders.vue')
          },
          {
            path: 'setting',
            name: 'MySetting',
            meta: { title: '设置中心' },
            component: () => import('../views/my/Setting.vue')
          }
        ]
      },
      {
        path: '/wxwork',
        component: () => import('../views/wxwork/WxWork.vue'),
        children: [
          {
            path: '/wxwork/payroll',
            meta: { title: '工资条' },
            component: () => import('../views/wxwork/PayrollView.vue')
          }
        ]
      },
      {
        path: '/crm/leads',
        name: 'LeadsPool',
        meta: { title: '客资管理-线索池' },
        component: () => import('../views/crm/LeadsPool.vue')
      },
      {
        path: '/crm/my_leads',
        name: 'MyLeads',
        meta: { title: '我的客资' },
        component: () => import('../views/crm/MyLeads.vue')
      },
      {
        path: '/crm/my_clientele',
        name: 'MyClientele',
        meta: { title: '我的客户' },
        component: () => import('../views/crm/MyClientele.vue')
      },
      {
        path: '/crm/dialogue',
        name: 'DialogView',
        meta: { title: '话术参考' },
        component: () => import('../views/crm/DialogView.vue')
      },
      {
        path: '/crm/my_dial_record',
        name: 'MyDialRecord',
        meta: { title: '我的通话记录' },
        component: () => import('../views/crm/MyDialRecord.vue')
      },
      {
        path: '/crm/dashboard/leads',
        name: 'CRMDashboardLeads',
        meta: { title: 'CRM数据看板' },
        component: () => import('../views/crm/DashboardView.vue')
      },
      {
        path: '/tools',
        name: 'Tools',
        meta: { title: '工具库' },
        component: () => import('../views/tools/ToolList.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录' },
    component: LoginView
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem('token')
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = 'MC-OMS'
  }
  if (token == null && to.name !== 'login') {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
